@media (max-width: 1120px) {
}

@media (max-width: 992px) {

    .header_area {
        min-height: auto;
    }

    .slider_section {
        padding: 75px 0;
    }

    .slider_section .detail-box h1 {
        font-size: 2.5rem;
    }

    .slider_section .detail-box h1 span {
        font-size: 4rem;
    }
}

@media (max-width: 767px) {
    .header_area .header_bg_box img {
        -o-object-position: center;
        object-position: center;
    }

    .slider_section .detail-box {
        text-align: center;
    }

    .about_section .img-box,
    .care_section .img-box {
        margin-bottom: 45px;
    }

    .care_section .row {
        flex-direction: column-reverse;
    }

    .contact_section .map_container {
        margin: 0;
        margin-top: 45px;
    }
}

@media (max-width: 576px) {
}

@media (max-width: 480px) {
}

@media (max-width: 420px) {
}

@media (max-width: 375px) {
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}